import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function B2BBusiness() {
  return (
    <div>
      <Header />
      <>
        <div class="bread">
          <h1 className="breadtext">B2B Business</h1>
        </div>
        <div className="about_section">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <h5>
                  {/* modified H5 */}
                All the Accreditations, Awards, Acknowledgements, etc are not
 helpful if the public does not know the Health Services & Offers
 available at your center. Together, we can let people know about
 your Health Care Unit and help them book your facilities at
 the comfort of their homes.
                </h5>
                <div className="abt_sec">
                  <div className="grid">
                    <figure
                      className="effect-jazz wow  animated bounceInRight"
                      data-wow-duration="3s"
                    >
                      <img
                        src="assets/images/a1.jpg"
                        alt="IOT"
                        style={{ height: "300px", width: "100%" }}
                      />
                      <figcaption>
                        <h2>
                          <b>Test At Home</b>
                        </h2>
                      </figcaption>
                    </figure>
                    <figure
                      className="effect-jazz wow  animated bounceInRight"
                      data-wow-duration="3s"
                    >
                      <img
                        src="assets/images/a2.jpg"
                        alt="img20"
                        style={{ height: "300px", width: "100%" }}
                      />
                      <figcaption>
                        <h2>
                          <b>Investigation </b>
                        </h2>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="grid">
                    <figure
                      className="effect-jazz wow  animated bounceInRight"
                      data-wow-duration="3s"
                    >
                      <img
                        src="assets/images/a3.jpg"
                        alt="img25"
                        style={{ height: "300px", width: "100%" }}
                      />
                      <figcaption>
                        <h2>
                          <b>Doctor Consultation</b>
                        </h2>
                      </figcaption>
                    </figure>
                    <figure
                      className="effect-jazz wow  animated bounceInUp"
                      data-wow-duration="3s"
                    >
                      <img
                        src="assets/images/a5.jpg"
                        alt="img06"
                        style={{ height: "300px", width: "100%" }}
                      />
                      <figcaption>
                        <h2>
                          <b>Home Service</b>
                        </h2>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
}

export default B2BBusiness;
