import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import Blog from "./Components/Blog";
import Contact from "./Components/Contact";
import Career from "./Components/Career";
import B2BBusiness from "./Components/B2BBusiness";
import Termsandconditions from "./Components/Termsandconditions";
import Privacypolicy from "./Components/Privacypolicy";
import Shippingpolicy from "./Components/Shippingpolicy";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/b2business" element={<B2BBusiness />} />
          <Route path="/Termsandconditions" element={<Termsandconditions />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Shippingpolicy" element={<Shippingpolicy />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
