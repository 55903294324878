import React from "react";
import CallIcon from "./CallIcon";


const HealthService = () => {
  return (
   <div>
     <div className="icon-right">
      <a className="call-icon"  href="tel:+8333827633" target="_blank" >
       <CallIcon/>
      </a>
    </div>
   </div>
  );
};

export default HealthService;
