import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function Privacypolicy() {
  const [form, setform] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.actinplus.com/v1/actinapi/admin/setting/webgetSetting",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform(res.data.setting);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            alert("not getting");
          }
        }
      );
  };

  return (
    <div>
      <Header />
      <div className="container">
        {form.privacyPolicy == null ? (
          <>
            <div className="card" style={{ height: "500px" }}>
              <div className="cardbody">
                <h1 style={{ textAlign: "center", marginTop: "200px" }}>
                  No Data
                </h1>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="card"
              style={{
                borderRadius: "15px",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              <div className="card-body m-3">
                <div className="card-body m-3">
                  <div
                    className="pt-5 pb-5"
                    dangerouslySetInnerHTML={{
                      __html: form.privacyPolicy,
                    }}
                  ></div>
                </div>
              </div>
            </div>{" "}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Privacypolicy;
