import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Header() {
  const [show, setshow] = useState(false);

  const shows1 =
    show == true
      ? "navbar-collapse js-navbar-collapse collapse in"
      : "navbar-collapse js-navbar-collapse collapse";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div>
      <div className="navigation_section">
        <nav className="navbar navbar-default navbar-fixed-top navigation_link">
          <div className="container">
            <div className="navbar-header">
              <button
                className="navbar-toggle"
                type="button"
                data-toggle="collapse"
                data-target=".js-navbar-collapse"
                onClick={() => {
                  setshow(!show);
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" /> <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="navbar-brand" href="#">
                <img src="assets/images/logo.png" className="img-responsive" />
              </a>
            </div>
            <div className={shows1}>
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <NavLink to="/">Home </NavLink>
                </li>
                <li>
                  <NavLink to="/aboutus">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/blog">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/b2business">B2B Business</NavLink>
                </li>
                <li>
                  <NavLink to="/career">Careers</NavLink>
                </li>
                <li>
                  <NavLink to="/contact">Contact Us</NavLink>
                </li>
                <li>
                  <a
                    href="https://vendor.actinplus.com/login"
                    target="_blank"
                  >
                    <div className="btn btn-default submitbtn">Log In</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
