import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const [form, setform] = useState([]);

  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const changeHandler = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (
      type == "jpg" ||
      type == "jpeg" ||
      type == "png" ||
      type == "JPG" ||
      type == "JPEG" ||
      type == "PNG" ||
      type == "JPEG"
    ) {
      setFiles(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };

  const changeHandler1 = (e) => {
    const file = e.target.files;
    var ext = file[0].name.split(".").pop();
    var type = ext;
    if (type == "pdf" || type == "PDF") {
      setFiles1(e.target.files);
    } else {
      e.target.value = null;
      toast("file format not supported.Pls choose Image");
    }
  };

  const Add = () => {
    const dataArray = new FormData();
    dataArray.append("firstName", form.firstName);
    dataArray.append("lastName", form.lastName);
    dataArray.append("phone", form.phone);
    dataArray.append("stateName", form.stateName);
    dataArray.append("districtName", form.districtName);
    dataArray.append("cityName", form.cityName);
    dataArray.append("address", form.address);
    dataArray.append("email", form.email);
    dataArray.append("qualification", form.qualification);
    dataArray.append("yearOfGraduation", form.yearOfGraduation);
    dataArray.append("yearsOfExperience", form.yearsOfExperience);

    for (let i = 0; i < files.length; i++) {
      dataArray.append("profileImage", files[i]);
    }
    for (let i = 0; i < files1.length; i++) {
      dataArray.append("identifyImage", files1[i]);
    }

    axios
      .post(
        "https://api.actinplus.com/v1/actinapi/web/atprecruitment/addatpprofile",
        dataArray,
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setform({
              firstName: "",
              lastName: "",
              phone: "",
              stateName: "",
              districtName: "",
              cityName: "",
              address: "",
              email: "",
              qualification: "",
              yearOfGraduation: "",
              yearsOfExperience: "",
            });
            setFiles({ image: "" });
            setFiles1({ image: "" });
            window.location.reload();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <Header />
      <div class="bread">
        <h1 className="breadtext">Careers</h1>
      </div>
      <div className="about_section" style={{ marginBottom: "50px" }}>
        <div className="container ">
          <div className="row">
            <div className="col-xs-12 col-md-8 col-sm-offset-2">
              <div className="job_section wow" style={{ textAlign: "left" }}>
                <h1>Job Apply</h1>
                <div className="applyform" style={{ textAlign: "left" }}>
                  <form
                    onSubmit={(e) => {
                      formsubmit(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="name">First Name:</label>
                          <input
                            type="text"
                            id="name"
                            required
                            className="form-control"
                            placeholder="Enter First name"
                            name="firstName"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.firstName}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name"> Phone:</label>
                          <input
                            type="text"
                            name="phone"
                            required
                            minLength="10"
                            maxLength="10"
                            pattern="[0-9]+"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.phone}
                            id="name"
                            className="form-control"
                            placeholder="Enter Phone"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Qualification:</label>
                          <input
                            type="text"
                            name="qualification"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.qualification}
                            id="name"
                            className="form-control"
                            placeholder="Enter Your Qualification"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">Year Of Experience:</label>
                          <input
                            type="number"
                            name="yearsOfExperience"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.yearsOfExperience}
                            id="name"
                            className="form-control"
                            placeholder="Enter  Year Of Experience"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="mail">District:</label>
                          <input
                            name="districtName"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.districtName}
                            type="text"
                            id="email"
                            className="form-control"
                            placeholder="Enter District"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="resume">Upload Profile:</label>
                          <input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="mail">Last Name:</label>
                          <input
                            type="text"
                            id="email"
                            required
                            name="lastName"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.lastName}
                            className="form-control"
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="mail">Email Id:</label>
                          <input
                            type="email"
                            id="email"
                            required
                            name="email"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.email}
                            className="form-control"
                            placeholder="Enter Email Id"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="mail">Year Of Graducation:</label>
                          <input
                            type="year"
                            id="email"
                            required
                            name="yearOfGraduation"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.yearOfGraduation}
                            className="form-control"
                            placeholder="Enter Year Of Graducation"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">State:</label>
                          <input
                            type="text"
                            required
                            name="stateName"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.stateName}
                            id="name"
                            className="form-control"
                            placeholder="Enter State"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="name">City :</label>
                          <input
                            type="text"
                            required
                            name="cityName"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.cityName}
                            id="name"
                            className="form-control"
                            placeholder="Enter City"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="resume">Upload Resume:</label>
                          <input
                            type="file"
                            className="form-control"
                            id="basicpill-firstname-input1"
                            placeholder="Enter image"
                            name="image"
                            onChange={changeHandler1}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="mail">Address:</label>
                          <textarea
                            className="form-control"
                            required
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="address"
                            value={form.address}
                            rows="3"
                            type="text"
                            placeholder="Address"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn  submitbtn" style={{float:"right"}}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Contact;
