import React, { useState } from "react";
import Slider from "react-slick";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Card } from "react-bootstrap";

function Home() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      contactNumber: form.contactNumber,
      description: form.description,
    };

    axios
      .post(
        "https://api.actinplus.com/v1/actinapi/web/contactus/addwebcontactus",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("will get back to you");
            setform({
              firstName: "",
              lastName: "",
              email: "",
              contactNumber: "",
              description: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <>
        <Header />
        <div className="banner_new">
          <div className="container">
            <div
              className="col-md-6 col-sm-6 col-xs-12 wow swing"
              data-wow-duration="1s"
            >
              <h3 className="contss">
                A-Accessible, C-Comfortable, T-Trustable, IN-INvestigations PLUS
                a lot more health services
              </h3>
              <div className="space-10"></div>
              <p style={{ color: "white" }}>
                As our name itself reveals, we are determined to provide QUALITY
                Health Services Accessible to all its users at the Comfort of
                their location at Trustable Health Care Units(Hospitals,
                Diagnostics, Clinics etc). We provide investigation PLUS a lot
                more Out Patient (O.P) health services. It includes Blood tests,
                Radiological Investigations, Doctor Consultations, Home Services
                like Physiotherapy, Sleep Study, etc
              </p>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div
                className="vegga_phone wow bounceInDown animated"
                data-wow-duration="1s"
              >
                <p className="contsss">
                  <img
                    src="assets/images/imms.png"
                    className="img-responsive"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="winga_section">
          <div className="mobile_sec3">
            <div className="container">
              <div className="main_service">
                <h1 style={{ color: "white" }}>User App</h1>
                <div className="row">
                  <div className="col-md-12">
                    <Slider {...settings}>
                      <div>
                        <img
                          src="assets/images/screnshots/img1.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img2.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img3.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img4.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img5.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img6.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img7.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img8.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                      <div>
                        <img
                          src="assets/images/screnshots/img9.png"
                          alt=""
                          className="mobilej"
                        />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="winga_bg" />
        <div className="winga_section">
          <div className="mobile_sec">
            <div className="img_bg">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="app_left">
                      <ul className="app_icons wow  animated bounceInLeft">
                        <li>
                          <img
                            src="assets/images/actinuserapp.png"
                            className="img-responsive"
                          />
                        </li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.actin.doctor&pcampaignid=web_share"
                          target="_blank"
                        >
                          <li>
                            <img
                              src="assets/images/gplay300x103.png"
                              className="img-responsive"
                            />
                          </li>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.actin.doctor&pcampaignid=web_share"
                          target="_blank"
                        >
                          <li>
                            <img
                              src="assets/images/apple300x103.png"
                              className="img-responsive"
                            />{" "}
                          </li>
                        </a>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app-symbol">
                      <img
                        src="assets/images/iphone.svg"
                        className="img-responsive ios"
                        height="250px"
                      />
                      <img
                        src="assets/images/screnshots/img21.png"
                        className="img-responsive mobile_img hidden-sm hidden-md hidden-lg"
                        height="250px"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app_left app_text wow  animated bounceInRight">
                      <h3 style={{ color: "#B30706" }}>USER APP </h3>
                      <p>
                        <small>
                          More than certificates of Quality, the TRUST of the
                          patient in an HCU and the accessibility of the Health
                          Service of that HCU is vital for determining the
                          course of treatment. If a health service is not
                          accessible to the patient in time, it is of no value
                          both to the patient and HCU. Many times, the patient
                          fails to get proper treatment due to inaccessibility
                          to quality health services at their trusted hospitals.
                          ACTIN plays a role of bridge in filling this gap.
                          ACTIN in collaboration with reputable Hospitals,
                          Diagnostic Centers provides Quality Health Services at
                          the doorstep of every patient in need. Even the
                          general public can book their regular health checkups
                          at their trusted centers
                        </small>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="winga_bg1" />
        <div className="winga_section">
          <div className="mobile_sec1">
            <div className="img_bg1">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="app_left app_text wow  animated bounceInRight">
                      <h3 style={{ color: "#B30706" }}>Dr ACTIN APP</h3>
                      <p>
                        <small>
                          The Dr Actin app helps the Doctor manage his/her
                          appointments seamlessly. The Doctor can see the
                          details of the patient and the time of appointment a
                          week ahead.After successfully completing the
                          consultation, the Doctor can upload the prescription
                          which is submitted to the patient using the ACTINplus
                          app.The prescriptions of the Doctor Consultations are
                          also available for quick reference for your medicines
                          consumption or purchase
                        </small>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app-symbol">
                      <img
                        src="assets/images/iphone.svg"
                        className="img-responsive ios"
                        height="250px"
                      />
                      <img
                        src="assets/images/screnshots/img22.png"
                        className="img-responsive mobile_img hidden-sm hidden-md hidden-lg"
                        height="250px"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app_left">
                      <ul className="app_icons  wow  animated bounceInLeft">
                        <li>
                          <img
                            src="assets/images/actindoctor.png"
                            className="img-responsive"
                          />
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/gplay300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/apple300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="winga_bg2" />
        <div className="winga_section">
          <div className="mobile_sec2">
            <div className="img_bg2">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="app_left">
                      <ul className="app_icons  wow  animated bounceInLeft">
                        <li>
                          <img
                            src="assets/images/actinatp.png"
                            className="img-responsive"
                          />
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/gplay300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/apple300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app-symbol">
                      <img
                        src="assets/images/iphone.svg"
                        className="img-responsive ios"
                        height="250px"
                      />
                      <img
                        src="assets/images/screnshots/img23.png"
                        className="img-responsive mobile_img hidden-sm hidden-md hidden-lg"
                        height="250px"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app_left app_text wow  animated bounceInRight">
                      <h3 style={{ color: "#B30706" }}>ATP APP</h3>
                      <p>
                        {" "}
                        <small>
                          Just as the muscle filament ACTIN can not move without
                          ATP(energy unit), ACTINplus also can not collect
                          quality samples without our ATP-Any Time Phlebotomist
                          .Our ATPs are available round the clock to collect
                          samples from any corner. They are well-experienced and
                          trained phlebotomists who have provided excellent
                          services to the public.With the Actin ATP app, the
                          phlebotomist gets notified when ever ACTIN Plus users
                          makes a request for sample collection. Our ATP will
                          collect the samples from the user’s location and
                          submit the samples at their trusted Health Care Unit
                          chosen by the user
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="winga_bg6" />
        <div className="winga_section">
          <div className="mobile_sec6">
            <div className="img_bg6">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="app_left app_text wow  animated bounceInRight">
                      <p>
                        <small>
                          WHAT MAKES ACTINPLUS SO SPECIAL IS IT IS THE FIRST OF
                          IT IS KIND TO PROVIDE BLOOD TESTS TO CGHS
                          BENEFICIARIES AT CGHS PRICE AT CGHS EMPANELLED
                          HOSPITALS, DIAGNOSTIC CENTERS
                        </small>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app-symbol">
                      <img
                        src="assets/images/iphone.svg"
                        className="img-responsive ios"
                        height="250px"
                      />
                      <img
                        src="assets/images/screnshots/img24.png"
                        className="img-responsive mobile_img hidden-sm hidden-md hidden-lg"
                        height="250px"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app_left">
                      <ul className="app_icons  wow  animated bounceInLeft">
                        <li>
                          <img
                            src="assets/images/actinuserapp.png"
                            className="img-responsive"
                          />
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/gplay300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/apple300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="winga_bg7" />
        <div className="winga_section">
          <div className="mobile_sec7">
            <div className="img_bg7">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="app_left">
                      <ul className="app_icons  wow  animated bounceInLeft">
                        <li>
                          <img
                            src="assets/images/actinuserapp.png"
                            className="img-responsive"
                          />
                        </li>
                        <li>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/gplay300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            href="https://play.google.com/store/apps/details?id=com.actin.user&pcampaignid=web_share"
                            target="_blank"
                          >
                            <img
                              src="assets/images/apple300x103.png"
                              className="img-responsive"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app-symbol">
                      <img
                        src="assets/images/iphone.svg"
                        className="img-responsive ios"
                        height="250px"
                      />
                      <img
                        src="assets/images/screnshots/img25.png"
                        className="img-responsive mobile_img hidden-sm hidden-md hidden-lg"
                        height="250px"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="app_left app_text wow  animated bounceInRight">
                      <p>
                        <small>
                          Another UNIQUE FEATURE IN USER APP IS “REM-MEDI”:
                          REMIND MEDICINES, where the user can set Reminders for
                          their daily consumption of medicines at the right
                          time. Even the user can send the status of their
                          medicines consumption to their dear ones so that they
                          can keep track of the health. The history of timely
                          medicines consumption of the past three months is
                          saved in the app which can be shared with the treating
                          Doctor who will decide the course of treatment.
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="winga_bg3" />
        <div className="about_section wing_sec">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-8 col-md-offset-2">
                <div
                  className="mobileapp_sec wow  animated fadeInUp"
                  data-wow-duration="3s"
                >
                  <h4
                    className="qu wow  animated fadeInUp center"
                    data-wow-duration="3s"
                  >
                    Come Join Us!!
                  </h4>
                  <br />
                  <p>
                    Let's make a difference in the quality of health care and
                    standard of living
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about_section keysec" id="contact">
          <div className="container">
            <div className="pro_sec">
              <div className="container">
                <div className="row">
                  <div className="pro_section">
                    <div className="contact">
                      <div className="col-sm-6 col-md-4">
                        <i
                          className="fa icon fa-phone fa-3x"
                          aria-hidden="true"
                        />
                        <h4>CALL US</h4>
                        040-45032828<br></br>+91 7997772468
                      </div>
                    </div>
                    <div className="contact">
                      <div className="col-sm-6 col-md-4">
                        <a href="mailto:info@Actin.com">
                          <i
                            className="fa icon fa-envelope fa-3x"
                            aria-hidden="true"
                          />
                        </a>
                        <h4>E-mail</h4>
                        office@actinplus.com<br></br>
                        hrd.actinplus@gmail.com
                      </div>
                    </div>
                    <div className="contact">
                      <div className="col-sm-6 col-md-4">
                        <a href="mailto:info@Actin.com">
                          <i
                            className="fa icon fa-home fa-3x"
                            aria-hidden="true"
                          />
                        </a>
                        <h4>Address:</h4>H No: 10-1-18/D/3/D, Flat-203, First
                        Floor, Muntazim Jung Plaza, Masab Tank, Hyderabad,
                        Telangana-500028.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about_section">
              <div className="container">
                <div
                  className="row"
                  style={{ marginTop: "50px", marginBottom: "50px" }}
                >
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="contact_left">
                      <Card>
                        <h1>Contact Us</h1>
                        <form
                          onSubmit={(e) => {
                            formsubmit(e);
                          }}
                        >
                          <div />
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="firstName"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  required
                                  value={form.firstName}
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  name="lastName"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  required
                                  value={form.lastName}
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  defaultValue=""
                                  name="email"
                                  required
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  required
                                  maxlength="10"
                                  minLength="10"
                                  pattern="[0-9]{10}"
                                  className="form-control"
                                  name="contactNumber"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.contactNumber}
                                  placeholder="Phone no"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  required
                                  rows={4}
                                  placeholder="Message"
                                  name="description"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.description}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-default submitbtn"
                          >
                            Submit
                          </button>
                        </form>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </>
    </div>
  );
}

export default Home;
