import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Shippingpolicy() {
  return (
    <div>
      <Header />
      <div className="card" style={{ height: "500px" }}>
        <div className="cardbody">
          <h1 style={{ textAlign: "center", marginTop: "200px" }}>
            We do not have any shipping policy.
          </h1>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shippingpolicy;
