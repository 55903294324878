import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function About() {
  return (
    <div>
      <Header />
      <div class="bread">
        <h1 className="breadtext"> About Us</h1>
      </div>
      <div className="about_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="abt_sec ">
                <h4 className="pb-4">
                  A-Accessible, C-Comfortable, T-Trustable, IN-INvestigations
                  PLUS a lot more health services
                </h4>
                <div className="pro_sec">
                  <div className="row">
                    <div className="col-sm-7 col-sm-push-5 nopadding">
                      <div
                        className="product_image"
                        wow=""
                        bounceinright=""
                        data-wow-duration="3s"
                        style={{
                          visibility: "visible",
                          animationDuration: "3s",
                          animationName: "bounceInRight",
                        }}
                      >
                        <h3>
                          <img
                            src="assets/images/cis.jpg"
                            className="img-responsive"
                            style={{ height: "505px", width: "90%" }}
                          />
                        </h3>
                      </div>
                    </div>
                    <div className="col-sm-5 col-sm-pull-7 nopadding">
                      <div className="product_text about-vision">
                        <h2 style={{ color: "#005da3" }}>About us </h2>
                        <p>
                          Post covid, the world came to its senses that Health
                          must be given top priority both as an individual and
                          as a society. As a result many Hospitals, Diagnostic
                          centers, and Clinics popped up all over the country.
                          Despite so many healthcare units, quality health
                          services are always inaccessible and out of reach to
                          the general public. In regions where they are
                          available, many don’t have time to spare for regular
                          checkups, consultations, and treatments due to their
                          busy daily routine which hinders them from leading a
                          healthy life. As a result, they get misguided by some
                          social media videos, articles, and ads for easy and
                          quick fixes not giving much thought to whether they
                          are really good for your health.
                        </p>
                        <p className="mt-3">
                          So our founder R.M.SIMON RAJU Garu, started this
                          endeavor to provide Quality Health Service at the
                          fingertips of every individual in need. His vision was
                          born in 2021 after seeing the suffering of his
                          colleagues, friends, relatives, and family during the
                          COVID & lockdown times. Being a Teacher by profession,
                          pursued Law by passion, he started his journey by
                          taking note of the patient’s needs and inputs from
                          different sectors of Health. He concluded that
                          admissions in Hospitals can be minimized if quality
                          health services are provided and availed by every
                          individual.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
        <div className="abtwinny">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="patro">
                  <h3 className="text-center" style={{ color: "#005da3" }}>
                    <b>ADVISORS </b>
                  </h3>
                  <p>
                    Hence,R.M.SIMON RAJU garu started ACTINPLUS with a single
                    motto <br></br> “#helpingforhealth
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clearfix" />
      </div>
      <Footer />
    </div>
  );
}

export default About;
