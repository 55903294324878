import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Contact() {
  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      contactNumber: form.contactNumber,
      description: form.description,
    };

    axios
      .post(
        "https://api.actinplus.com/v1/actinapi/web/contactus/addwebcontactus",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("will get back to you");
            setform({
              firstName: "",
              lastName: "",
              email: "",
              contactNumber: "",
              description: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      <Header />
      <>
        <div class="bread">
          <h1 className="breadtext"> Contact us</h1>
        </div>
        <div className="about_section keysec" id="contact">
          <div className="container">
            <div className="pro_sec">
              <div className="container">
                <div className="row">
                  <div className="pro_section">
                    <div className="contact">
                      <div className="col-sm-6 col-md-4">
                        <i
                          className="fa icon fa-phone fa-3x"
                          aria-hidden="true"
                        />
                        <h4>CALL US</h4>
                        040-45032828<br></br>+91 7997772468
                      </div>
                    </div>
                    <div className="contact">
                      <div className="col-sm-6 col-md-4">
                        <a href="mailto:info@Actin.com">
                          <i
                            className="fa icon fa-envelope fa-3x"
                            aria-hidden="true"
                          />
                        </a>
                        <h4>E-mail</h4>
                        office@actinplus.com<br></br>
                        hrd.actinplus@gmail.com
                      </div>
                    </div>
                    <div className="contact">
                      <div className="col-sm-6 col-md-4">
                        <a href="mailto:info@Actin.com">
                          <i
                            className="fa icon fa-home fa-3x"
                            aria-hidden="true"
                          />
                        </a>
                        <h4>Address:</h4>H No: 10-1-18/D/3/D, Flat-203, First
                        Floor, Muntazim Jung Plaza, Masab Tank, Hyderabad,
                        Telangana-500028.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about_section">
              <div className="container">
                <div
                  className="row"
                  style={{ marginTop: "50px", marginBottom: "50px" }}
                >
                  <div className="cont">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="contact_left">
                        <h1>Contact Us</h1>
                        <form
                          onSubmit={(e) => {
                            formsubmit(e);
                          }}
                        >
                          <div />
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="firstName"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  required
                                  value={form.firstName}
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  name="lastName"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  required
                                  value={form.lastName}
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  defaultValue=""
                                  name="email"
                                  required
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <input
                                  type="number"
                                  required
                                  maxlength="10"
                                  minLength="10"
                                  pattern="[0-9]{10}"
                                  className="form-control"
                                  name="contactNumber"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.contactNumber}
                                  placeholder="Phone no"
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  required
                                  rows={4}
                                  placeholder="Message"
                                  name="description"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.description}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="btn btn-default submitbtn"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about_section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="cont-map">
                  <div id="map" style={{ height: 400, width: "100%" }}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15228.941225191222!2d78.44110969976654!3d17.400491661675794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb971466381c91%3A0xc855906f2903c236!2sMasab%20Tank%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1718621729384!5m2!1sen!2sin"
                      width="100%"
                      height="400px"
                      frameBorder={0}
                      style={{ border: 0 }}
                      allowFullScreen=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Contact;
