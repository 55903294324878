import React from "react";
import HealthService from "./HealthService";

function Footer() {
  return (
    <div>
      {" "}
      <div className="footer_section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ftr_sec">
                <div className="row">
                  <div className="col-sm-4">
                    <div
                      className="ftr_rgt wow pulse animated"
                      data-wow-duration="1s"
                    >
                      <p>
                        <img
                          src="assets/images/logo.png"
                          className="img-responsive"
                          style={{ background: "white" }}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <ul className="social_links">
                      <li>
                        <a
                          href="https://www.facebook.com/profile.php?id=61555855756280"
                          target="_blank"
                        >
                          <i className="fa fa-facebook" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://youtube.com/@actinplus-ddviews?si=VvUdvshFvB3Mq6rc"
                          target="_blank"
                        >
                          <i className="fa fa-youtube" aria-hidden="true" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/actinplus?igsh=aTY5dzM0cTRwZWV0"
                          target="_blank"
                        >
                          <i className="fa fa-instagram" aria-hidden="true" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="ftr_lft wow  pulse  animated"
                      data-wow-duration="1s"
                    >
                      <h1>Reg Office Address:</h1>
                      <address>
                        H No: 10-1-18/D/3/D, Flat-203, First Floor, Muntazim
                        Jung Plaza, Masab Tank, Hyderabad, Telangana-500028.
                      </address>
                    </div>
                    <div>
                      <a href="Termsandconditions" style={{ color: "white" }}>
                        Terms & Conditions
                      </a>
                      <span
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          color: "white",
                        }}
                      >
                        /
                      </span>
                      <a href="Shippingpolicy" style={{ color: "white" }}>
                        Shipping Policy
                      </a>

                      <span
                        style={{
                          marginLeft: "5px",
                          marginRight: "5px",
                          color: "white",
                        }}
                      >
                        /
                      </span>
                      <a href="Privacypolicy" style={{ color: "white" }}>
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_cpy">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="cpy_rgt">
                © 2024.
                <a
                  href="#"
                  style={{ color: "#005da3", textDecoration: "none" }}
                >
                  {" "}
                  Actin.
                </a>{" "}
                All Rights Reserved - Designed by
                <a
                  href="https://digitalraiz.com/"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  {" "}
                  Digitalraiz
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
                  <HealthService/>
    </div>
  );
}

export default Footer;
