import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function Blog() {
  const [users, setusers] = useState([]);

  useEffect(() => {
    Get();
  }, []);

  const Get = () => {
    axios
      .post("https://api.actinplus.com/v1/actinapi/web/blog/getallblogs", {})
      .then((res) => {
        setusers(res.data.blogs);
      });
  };

  const api = "https://api.actinplus.com/";

  return (
    <div>
      <Header />
      <div className="about_sections" style={{ marginBottom: "50px" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-offset-2">
              <div className="row">
                <div className="col-4">
                  <div>
                    <h2 className="mb-0">Blogs </h2>
                  </div>
                </div>
              </div>
              {users.map((data, i) => (
                <>
                  <hr className="mb-4" />
                  <div key={i}>
                    <div className="position-relative mb-3">
                      <img
                        src={api + data.image}
                        alt=""
                        className="img-thumbnail"
                      />
                      <div className="position-absolute mb-3">
                        <p style={{textTransform:"capitalize"}}>
                          <i class="fa fa-user"></i>By {data.autherName} <span> / </span>
                          <i class="fa fa-calendar"></i>
                          {data.logCreatedDate.slice(0,10)}
                        </p>
                      </div>
                    </div>
                    <h5 className="sidehead">
                      <a className="text-dark" >
                        {data.title}
                      </a>
                    </h5>
                    <p className="mt-5">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.description,
                        }}
                      ></div>
                    </p>
                    <div></div>
                  </div>
                  <hr className="my-5" />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
